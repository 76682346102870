<template>
    <div class="container-fluid">
        <Head></Head>
        <div class="row">
            <div class="col-lg-12"
                 style="top: -180px;overflow: hidden;height: 880px;width: 1920px">
                <div class="more_img" style="float: left">
<!--                    <img alt="" src="../../../assets/image/more/1.webp">-->
                </div>
                <div class="more_world" style="float: left;background-color: white !important;">
                    <div class="more_world_container">

                        <div class="more_world_title">
                            <h1 class="more01">Hard Drive Shredding</h1>
                        </div>

                        <div class="more_world_little_title">
                            <p class="more01">Competitive Prices</p>
                        </div>

                        <div class="more_world_middle">
                            <p class="more01">We provides mobile, quiet, speedy, and secure shredding services to organizations hard
                                drives, cell phones, backup tapes, DLT, LTO, thumb drives and other data storage
                                devices.
                                Performing these services at the client’s location enables their personnel to witness
                                the
                                process take place, thus maintaining the highest level of security and eliminating any
                                chain
                                of custody dilemmas.</p>
                            <p class="more01"> Our onsite services can shred thousands of pounds of metal and plastic per hour. The CINO
                                Recycling's hard drive shredding process has been independently verified and audited
                                based
                                on the industry’s best practices.</p>
                        </div>

                        <div class="more_world_last">
                            <a class="more01" href="javascript:;">More...</a>
                        </div>

                    </div>

                </div>
            </div>

            <div class="col-lg-12"
                 style="overflow: hidden;height: 880px;width: 1920px;margin-top: -145px">

                <div class="more_world" style="float: left;width: 50%">
                    <div class="more_world_container">

                        <div class="more_world_title">
                            <h1 class="more02">On-Site Shredding</h1>
                        </div>


                        <div class="more_world_middle">
                            <p class="more02">Our mobile onsite shredding provides quiet, speedy, and secure shredding services of hard
                                drives, cell phones, backup tapes, DLT, LTO, thumb drives and other data storage
                                devices. Performing these services at the client’s location enables their personnel to
                                witness the process take place, thus maintaining the highest level of security and
                                eliminating any chain of custody dilemmas. </p>
                        </div>


                    </div>

                </div>

                <div class="more_img" style="float: left;width: 45%;margin-left: 15px">
<!--                    <img alt="" src="../../../assets/image/more/2.webp">-->
                </div>
            </div>


            <div class="col-lg-12"
                 style="overflow: hidden;height: 880px;width: 1920px;margin-top: 28px">

                <div class="more_img" style="float: left;width: 50%;margin-left: 15px">
<!--                    <img alt="" src="../../../assets/image/more/3.webp">-->
                </div>

                <div class="more_world" style="float: left;width: 45%">
                    <div class="more_world_container">

                        <div class="more_world_title">
                            <h1 class="more03">Off-Site Shredding</h1>
                        </div>



                        <div class="more_world_middle">
                            <p class="more03">We provides a comprehensive list of end-to-end IT asset disposal services performed at
                                our secure, access controlled facilities in, including hard drive shredding, data
                                destruction and e-waste recycling. Recycling and data destruction plans can be tailored
                                to each organization’s needs, using any or all of CINO's certified processing
                                techniques.</p>
                        </div>


                    </div>

                </div>

            </div>


            <div class="col-lg-12">
                <div class="more_bottom_last">
                    <p class="more04">Our services were designed to ease your recycling efforts. Contact us today to find out how we
                        can accommodate you.</p>
                    <p class="more04">Schedule a Pickup</p>
                </div>
            </div>


        </div>
        <Bottom style="margin-left: -25px;margin-top: -45px"></Bottom>
    </div>
</template>

<script>
    import {Bottom, Head } from "@/view/layout/commponents";

    export default {
        name: "MoreOther",
         metaInfo: {
        meta: [{                 
            name: '暹诺回收官网,手机回收,笔记本回收,数码产品回收,企业回收',
            content: '上海暹诺环保科技有限公司 (简称: 暹诺环保©)是中国领先的环保解决方案提供商，为国内500多家商业和工业企业客户提供回收服务。我们投资开发电子垃圾解决方案，用以适应不断变化的世界。目前该领域不仅包括处理和回收，还包括确保敏感数据的安全的方案，以帮助我们的客户实现零废物的绿色目标。作为中国领先的电子废弃物管理服务供应商，我们的服务宗旨是在最大限度降低影响的同时，又能最大限度地提高资源价值，为所有利益相关者促进经济和环境的可持续发展。'
        }]
        },
        components: {Bottom, Head},
        created() {
            $(function () {
                $('.more01').animate({
                    opacity: '1',
                    top: '15px'
                }, 1000)


                $(window).scroll(function () {
                    var s = $(window).scrollTop();
                    console.log(s)
                    if (s > 450) {
                        $('.more02').animate({
                            opacity: '1',
                            top: '15px'
                        }, 1000)
                    }
                    if (s > 1450) {
                        $('.more03').animate({
                            opacity: '1',
                            top: '15px'
                        }, 1000)
                    }
                    if (s > 1850) {
                        $('.more04').animate({
                            opacity: '1',
                            top: '15px'
                        }, 1000)
                    }

                });


            })

        }
    }

    $(function () {
        $('.more01').animate({
            opacity: '1',
            top: '15px'
        }, 1000)


        $(window).scroll(function () {
            var s = $(window).scrollTop();
            console.log(s)
            if (s > 450) {
                $('.more02').animate({
                    opacity: '1',
                    top: '15px'
                }, 1000)
            }
            if (s > 1450) {
                $('.more03').animate({
                    opacity: '1',
                    top: '15px'
                }, 1000)
            }
            if (s > 1850) {
                $('.more04').animate({
                    opacity: '1',
                    top: '15px'
                }, 1000)
            }

        });


    })



</script>

<style scoped>
    .more01{
        opacity: 0;
        position: relative;
    }
    .more02{
        position: relative;
        opacity: 0;
    }
    .more03{
        position: relative;
        opacity: 0;
    }
    .more04{
        position: relative;
        opacity: 0;
    }

    .col-lg-12 .more_img {
        width: 50%;
        height: 100%;

    }

    .more_img img {
        height: 100%;
        width: 100%;
    }

    .col-lg-12 .more_world {
        width: 46%;
        height: 100%;
        background-color: #f1f1f1;
        margin: 0px 5px 0px 15px;

    }

    .more_world .more_world_container {
        height: 100%;
        width: 90%;
        position: relative;
        left: 50%;
        transform: translate(-50%);

        text-align: center;
    }

    .more_world_container .more_world_title {
        height: 105px;
        width: 100%;
        overflow: hidden;

        margin-top: 100px;
    }

    .more_world_title h1 {
        color: black;
        font-weight: 800;
    }

    .more_world_little_title {
        height: 45px;
        width: 100%;
        margin-top: 15px;

    }

    .more_world_little_title p {
        color: black;
        font-size: 22px;
    }

    .more_world_middle {
        height: 350px;
        width: 80%;
        position: relative;
        left: 50%;
        margin-top: 15px;
        transform: translate(-50%);
        line-height: 1.5rem;

    }

    .more_world_middle p {
        line-height: 1.5rem;
        color: black;
    }

    .more_world_last {
        height: 50px;
        width: 100%;

        margin-top: 25px;
    }

    .more_bottom_last {
        height: 120px;
        width: 100%;

        margin-top: 55px;
        text-align: center;
    }

    .more_bottom_last p {
        color: black;
        font-size: 17px;
    }

</style>
